import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ShapeContact from "../components/shapes/ShapeContact"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Polityka Prywatności" />

    <main className="default_page">
      <ShapeContact />

      <section className="page_header_wrapper">
        <h1 className="page_header" data-text="&#60;Polityka Prywatności/&#62;">
          &#60;Polityka Prywatności/&#62;
        </h1>
      </section>

      <section className="container-fluid default_section_1">
        <h3>Polityka Prywatności Agencji Interaktywnej ADream sp. z o.o.</h3>
        <p>Informacje podstawowe dotyczące przetwarzania danych osobowych</p>

        <h3>1. Administrator danych</h3>
        <p>
          Agencja Interaktywna ADream sp. z o.o., ul. Chopina 18/3a, 30-047
          Kraków, KRS: 0000517132 (dalej: ADream).
        </p>

        <h3>2. Dane kontaktowe</h3>
        <p>
          Z ADream można się skontaktować poprzez adres e-mail: biuro@adream.pl,
          pod numerem telefonu 690 888 343 lub pisemnie: Agencja Interaktywna
          ADream sp. z o.o., ul. Chopina 18/3a, 30-047 Kraków.
        </p>
        <p>ADream nie wyznaczył inspektora ochrony danych.</p>

        <h3>3. Cele przetwarzania oraz podstawa prawna przetwarzania</h3>
        <ul>
          <li>
            Pani/Pana dane będą przetwarzane w celu udzielenia dodatkowych
            informacji handlowych z zakresu oferty Agencji. Podanie danych jest
            dobrowolne i umożliwia uzyskanie informacji handlowej – podstawą
            prawną przetwarzania danych jest Pani/Pana zgoda pod warunkiem, że
            ta zgoda została przez Panią/Pana udzielona, w razie nieudzielenia
            zgody dane osobowe nie są przetwarzane w tym celu.
          </li>
          <li>
            Pani/Pana dane będą przetwarzane w celu marketingu bezpośredniego
            dotyczącego produktów i usług własnych ADream – podstawą prawną
            przetwarzania danych jest Pani/Pana zgoda pod warunkiem, że ta zgoda
            została przez Panią/Pana udzielona, w razie nieudzielenia zgody dane
            osobowe nie są przetwarzane w tym celu.
          </li>
          <li>
            Pani/Pana dane będą przetwarzane w celu realizacji usług takich jak
            np. udział w webinarze – podstawą prawną przetwarzania danych jest
            Pani/Pana zgoda pod warunkiem, że ta zgoda została przez Panią/Pana
            udzielona, w razie nieudzielenia zgody dane osobowe nie są
            przetwarzane w tym celu.
          </li>
        </ul>

        <h3>4. Okres, przez który dane będą przechowywane</h3>
        <p>
          Dane będą przetwarzane przez 2 lata lub do momentu cofnięcia przez
          Panią/Pana zgody na przetwarzanie danych osobowych w celach
          marketingowych.
        </p>

        <h3>5. Odbiorcy danych</h3>
        <p>
          Pani/Pana dane mogą być przekazywane podmiotom przetwarzającym dane
          osobowe na zlecenie ADream w szczególności dostawcom usług IT i biurom
          rachunkowym – przy czym takie podmioty będą przetwarzać dane na
          podstawie umowy z ADream i wyłącznie zgodnie z poleceniami ADream.
        </p>

        <h3>6. Przekazywanie danych poza Europejski Obszar Gospodarczy</h3>
        <p>
          Pani/Pana dane nie będą przekazywane do odbiorców znajdujących się w
          państwach poza Europejskim Obszarem Gospodarczym.
        </p>

        <h3>7. Prawa osoby, której dane dotyczą</h3>
        <p>Przysługuje Pani/Panu prawo:</p>
        <ul>
          <li>dostępu do Pani/Pana danych</li>
          <li>żądania ich sprostowania</li>
          <li>żądania ich usunięcia</li>
          <li>żądania ograniczenia przetwarzania,</li>
          <li>wycofania zgody,</li>
          <li>
            prawo do przenoszenia danych osobowych, tj. do otrzymania od ADream
            Pani/Pana danych osobowych w pliku o powszechnie używanym formacie,
          </li>
          <li>
            wniesienia skargi do organu nadzorczego zajmującego się ochroną
            danych osobowych.
          </li>
        </ul>
        <p>
          W celu realizacji powyżej wymienionych praw należy skontaktować się z
          ADream. Można to zrobić np. przesyłając wniosek na adres siedziby
          ADream lub e-mailowo na adres biuro@adream.pl
        </p>

        <h3>8. Dobrowolność podania danych</h3>
        <p>Podanie danych osobowych w celach marketingowych jest dobrowolne.</p>
        <p>
          Podanie danych osobowych w celu rozpatrywania ewentualnej reklamacji
          jest dobrowolne, jednak ich niepodanie uniemożliwi przyjęcie i
          rozpatrzenie reklamacji.
        </p>

        <h3>9. Podjęte środki ochrony danych</h3>
        <ul>
          <li>
            Ochrona danych odbywa się zgodnie z wymogami powszechnie
            obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na
            zabezpieczonych serwerach.
          </li>
          <li>
            Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W
            tym celu używany jest m.in. bezpieczny protokół szyfrowania
            komunikacji (SSL).
          </li>
          <li>
            Wdrożyliśmy szyfrowanie danych oraz mamy wprowadzoną kontrolę
            dostępu dzięki czemu minimalizujemy skutki ewentualnego naruszenia
            bezpieczeństwa danych.
          </li>
        </ul>

        <h3>10. Pliki cookies</h3>
        <ul>
          <li>
            Nasza witryna używa plików cookies. Są to niewielkie pliki tekstowe
            wysyłane przez serwer www i przechowywane przez oprogramowanie
            komputera przeglądarki. Kiedy przeglądarka ponownie połączy się ze
            stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się
            użytkownik. Parametry pozwalają na odczytanie informacji w nich
            zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają
            więc korzystanie z wcześniej odwiedzonych witryn. Gromadzone
            informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki,
            języka, rodzaju systemu operacyjnego, dostawcy usług internetowych,
            informacji o czasie i dacie, lokalizacji oraz informacji
            przesyłanych do witryny za pośrednictwem formularza kontaktowego.
          </li>
          <li>
            Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
            użytkownicy korzystają z naszych witryn, aby usprawniać
            funkcjonowanie serwisu zapewniając bardziej efektywną i
            bezproblemową nawigację. Monitorowania informacji o użytkownikach
            dokonujemy korzystając z narzędzia Google Analitics, które
            rejestruje zachowanie użytkownika na stronie.
          </li>
          <li>
            Cookies identyfikuje użytkownika, co pozwala na dopasowanie treści
            witryny, z której korzysta, do jego potrzeb. Zapamiętując jego
            preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego
            reklam. Stosujemy pliki cookies, aby zagwarantować najwyższy
            standard wygody naszego serwisu, a zebrane dane są wykorzystywane
            jedynie wewnątrz firmy ADream w celu optymalizacji działań.
          </li>
          <li>Na naszej witrynie wykorzystujemy następujące pliki cookies:</li>
          <ul>
            <li>
              „niezbędne” pliki cookies, umożliwiające korzystanie z usług
              dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies
              wykorzystywane do usług wymagających uwierzytelniania w ramach
              serwisu;
            </li>
            <li>
              pliki cookies służące do zapewnienia bezpieczeństwa, np.
              wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
              ramach serwisu;
            </li>
            <li>
              „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji
              o sposobie korzystania ze stron internetowych serwisu;
            </li>
            <li>
              „reklamowe” pliki cookies, umożliwiające dostarczanie użytkownikom
              treści reklamowych bardziej dostosowanych do ich zainteresowań.
            </li>
          </ul>
          <li>
            Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia
            opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce
            internetowej. Instrukcja zarządzania plikami cookies jest dostępna
            na stronie: http://www.allaboutcookies.org/manage-cookies{" "}
          </li>
          <li>
            Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w
            miejscach, w których użytkownik wypełniając formularz wyraźnie
            wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy
            tylko do potrzeb niezbędnych do wykonania danej funkcji.
          </li>
        </ul>
        <p>
          Więcej o ciasteczkach (cookies) w naszej{" "}
          <Link to="/polityka-cookies">Polityce Cookies</Link>.
        </p>
      </section>
    </main>
  </Layout>
)

export default PrivacyPolicy
